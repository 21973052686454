
// react
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import login from './login';
import map from './map';


function App() {

  return (
    <div className="App">

      <BrowserRouter>
        <div>
          {/* <div className="header">
            <NavLink exact activeClassName="active" to="/">login</NavLink>
            <NavLink activeClassName="active" to="/login">map</NavLink>
           
          </div> */}
          <div className="content">
            <Switch>
              <Route exact path="/" component={login} />
              <Route path="/map" component={map} />

            </Switch>
          </div>
        </div>
      </BrowserRouter>

    </div>
  );
}

export default App;
